// import { usePageContext } from "../../hooks/page-context";
import React, { createContext, useState } from "react";

// import SEO from "../../../../components/seo";

export function SiteLayout({ children, ...restProps }) {
  // const [context, setContext] = useState({ menuOpen: false });
  // const [pageContext] = usePageContext();
  // const { contentNode: { title, isFrontPage } = {} } = pageContext;

  return (
    <>
      {/*<SEO title={title} isFrontPage={isFrontPage} />*/}
      {/*<SiteLayoutContext.Provider value={[context, setContext]}>*/}
        <div {...restProps}>{children}</div>
      {/*</SiteLayoutContext.Provider>*/}
    </>
  );
}

export const SiteLayoutContext = createContext();
