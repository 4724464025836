const WP_URL = `${process.env.GATSBY_WORDPRESS_URL}`;

export function normalizeWordpressUrl(url) {
  if (typeof url !== "string") {
    return url;
  }
  const wpUrl = WP_URL.replace("/wp", "");
  // Only uploaded media should point to cms.cirkor.se, otherwise remove domain
  if (url.startsWith(`${wpUrl}/`) && !url.startsWith(`${wpUrl}/app/uploads/`)) {
    return url.substring(wpUrl.length);
  }
  return url;
}
