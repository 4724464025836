module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cirkus Cirkör","short_name":"Cirkör","start_url":"/","background_color":"#fff","theme_color":"#004071","display":"standalone","icon":"static/cirkor.svg","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"f23687386ee06f13242819d542a62888"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"navigateFallbackWhitelist":[{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://cirkor.se","stripQueryString":true},
    },{
      plugin: require('../src/@whitespace/gatsby-theme-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/home/circleci/repo","siteUrl":"https://cirkor.se","fragmentsDir":"/home/circleci/repo/src/fragments","wp":{"url":"https://cms.cirkor.se/wp/graphql","contentTypes":{"post":true,"page":true,"performance":true}},"csp":false,"manifest":{"name":"Cirkus Cirkör","short_name":"Cirkör","start_url":"/","background_color":"#fff","theme_color":"#004071","display":"standalone","icon":"static/cirkor.svg","include_favicon":true},"intl":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["sv","en"],"defaultLanguage":"sv","siteUrl":"https://cirkor.se","trailingSlash":"always","redirect":false,"i18nextOptions":{"defaultNS":"translation","interpolation":{"escapeValue":false},"nsSeparator":false,"debug":false},"pages":[{"matchPath":"/:lang?","getLanguageFromPath":true,"excludeLanguages":["sv","en"]},{"matchPath":"/:lang?/(.*)","getLanguageFromPath":true,"excludeLanguages":["sv"]}]},
    },{
      plugin: require('../node_modules/@whitespace/gatsby-theme-search/gatsby-browser.js'),
      options: {"plugins":[],"page":false,"backend":{"type":"local","miniSearch":{"storeFields":["title","url","language"],"searchOptions":{"boost":{"title":2},"fuzzy":0.2}},"query":"\n          {\n            wp {\n              languages {\n                name\n                code\n              }\n              contentNodes(\n                first: 10000\n                where: { contentTypes: [PAGE, POST, PERFORMANCE] }\n              ) {\n                nodes {\n                  id\n                  uri\n                  contentType {\n                    node {\n                      name\n                    }\n                  }\n                  ... on WP_Page {\n                    language {\n                      code\n                    }\n                  }\n                  ... on WP_Post {\n                    language {\n                      code\n                    }\n                  }\n                  ... on WP_Performance {\n                    language {\n                      code\n                    }\n                  }\n                  ... on WP_NodeWithTitle {\n                    title\n                  }\n                  ... on WP_NodeWithExcerpt {\n                    excerpt\n                  }\n                  ... on WP_NodeWithContentEditor {\n                    content\n                  }\n                  ... on WP_NodeWithFeaturedImage {\n                    featuredImage {\n                      node {\n                        sourceUrl\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        "}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W2KBBS","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://18e8541ab427424994d80c6a634531d5@sentry.frojd.se/34","tracesSampleRate":1,"environment":"production"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
