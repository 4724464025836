import React, { createContext } from "react";
import { HeadingLevelProvider } from "@jfrk/react-heading-levels";
import { IDContextProvider } from "@jfrk/react-id";

// import { AlertProvider } from "./hooks/alert";
import { SiteLayout } from "./components/SiteLayout";
// import { PageContextProvider } from "./hooks/page-context";

export const pageWrapperContext = createContext();

export const PageWrapperContextProvider = pageWrapperContext.Provider;

// This is run from gatsby-ssr.js and gatsby-browser.js
// Make sure any contexts updated here won't create multiple renditions on page load
export function PageWrapper({ context, children }) {
  return (
    <>
      <PageWrapperContextProvider value={context}>
        {/*<PageContextProvider>*/}
          <HeadingLevelProvider>
            <IDContextProvider>
              <SiteLayout>{children}</SiteLayout>
            </IDContextProvider>
          </HeadingLevelProvider>
        {/*</PageContextProvider>*/}
      </PageWrapperContextProvider>
    </>
  );
}
